import React from "react";

import {Box, Heading, Paragraph, Text} from "grommet";
import logo1 from "../images/university-of-isfahan-logo.svg";
import logo2 from "../images/heiderlab-logo.svg";
import logo3 from "../images/800px-Uni_Marburg_Logo.svg.png"
import logo4 from "../images/UNIALAB.jpg"
import "./About.css"



export const Contact = () => (
    <Box fill align="start">
        <Heading>Contact</Heading>

        <Box align={"center"} style={{flexDirection: 'row', padding: 20}}>
            <div>
                <a href={"/"} style={{textDecoration: 'none'}}>

                    <Text style={{fontWeight: "bold", color: "#FF1616"}}>
                        LAMP
                    </Text>
                    <Text style={{fontWeight: "bold", color: "#000000"}}>
                        Primer
                    </Text>
                    <Text style={{fontWeight: "bold", color: "#BCB8B1"}}>
                        Bank
                    </Text> </a>

                <Text> database is the result of a collaborative project of the University of Isfahan Nucleic Acids Laboratory (UINA Lab) and the research group Data Science in Biomedicine (Heiderlab). To gain further
                    information, to send feedback,to submit newly validated LAMP primers, or revise the existing data to
                    enhance the integrity of the database, please contact us. </Text>
            </div>

        </Box>


        <Box align={"baseline"} direction="row">
            <Box align={"center"} style={{padding: 20}}>

              <Box align={"center"} style={{padding: 20}} direction="column">
                    <a href="https://ui.ac.ir/en" target="_blank" rel=" noopener noreferrer">
                        <img height="200vw" src={logo1} alt=""/></a>
                    <a href="https://uina.ui.ac.ir/" target="_blank" rel=" noopener noreferrer">
                        <img name="image" height="100vw" src={logo4} alt=""/> </a>
                </Box>
                <Box align={"center"} style={{padding: 20}}>
                    <h2>University of Isfahan Nucleic Acids Laboratory (UINA Lab)</h2>
                    <Text className="Text">
                        {"\n"}
                        Department of Cell and Molecular Biology & Microbiology
                        {"\n"}</Text> {"\n"}


                    <Text>University of Isfahan</Text>

                    <Text>Azadi Square, Hezar Jerib Avenue 8174673441</Text>
                    <Text>Isfahan, Iran</Text>
                    <Paragraph> Head of Laboratory: <a
                        href=" https://scholar.google.de/citations?user=e-IfCK4AAAAJ&hl=de&oi=sra" target="_blank"
                        rel="noopener noreferrer"> <Text style={{fontWeight: "bold", color: "#FF1616"}}>Dr. Fatemeh
                        Javadi-Zarnaghi</Text> </a></Paragraph>
                    <Text>E-Mail: <Text style={{color: "#FF1616"}}>fa.javadi@sci.ui.ac.ir, ui.biochemistry@gmail.com</Text></Text>
                    <Text>Office: <Text style={{color: "#FF1616"}}>(+98-31) 37934128</Text></Text>
                    <Text>Fax: <Text style={{color: "#FF1616"}}>(+98-31) 3793-2456</Text></Text>
                </Box>
            </Box>
            <Box>


                <Box align={"center"} style={{padding: 20}}>

                   <Box align={"center"} style={{padding: 20}} direction="column">

                        <a href="https://heiderlab.de/" target="_blank" rel=" noopener noreferrer">
                            <img name="image" height="200vw" src={logo2} alt=""/> </a>
                        <a href=" https://www.uni-marburg.de/en" target="_blank" rel=" noopener noreferrer">
                            <img height="100vw" src={logo3} alt=""/></a>
                    </Box>
                    <Box align={"center"} style={{padding: 20}}>
                        <h2>Heiderlab</h2>
                        <Text>Department of Mathematics and Computer Science</Text>
                        <Text>University of Marburg</Text>

                        <Text>Hans-Meerwein-Str. 6</Text>
                        <Text>Marburg, Germany</Text>
                        <Paragraph>Working group: <a href=" https://heiderlab.de/?page_id=26" target="_blank"
                                                     rel="noreferrer noreferrer"> <Text
                            style={{fontWeight: "bold", color: "#FF1616"}}>Data Science in
                            Biomedicine </Text></a></Paragraph>
                        <Text>E-Mail:<Text style={{color: "#FF1616"}}> ag.heider.umr@gmail.com</Text></Text>
                    </Box>
                </Box>
            </Box>
        </Box>
    </Box>
);
