import React, {Component} from "react";

import logo from "../images/LPB-Homepage figure.png";

import {Box, Text} from "grommet";


const size = 20;
const brand = "#FF1616";


export class About extends Component {


    render() {
        return (

            <Box align={"start"} direction="column">
                <Box direction={"column"}>
                    <Box direction={"column"}>
                        <Box justify={"start"} >
                            <span>&nbsp;&nbsp;</span>
                            <span>&nbsp;&nbsp;</span>
                            <Text>
                                <a href={"/"} style={{textDecoration: 'none'}}>

                                    <Text style={{fontWeight: "bold", color: "#FF1616"}}>
                                        LAMP
                                    </Text>
                                    <Text style={{fontWeight: "bold", color: "#000000"}}>
                                        Primer
                                    </Text>
                                    <Text style={{fontWeight: "bold", color: "#BCB8B1"}}>
                                        Bank
                                    </Text> </a>

                                <Text> is an online, manually curated </Text>
                                <Text style={{fontWeight: "bold"}}> database </Text>

                                <Text>
                                    dedicated to experimentally validated loop-mediated isothermal amplification

                                    <Text style={{fontWeight: "bold"}}> (LAMP) primers </Text> and their details of assays and
                                    accompanying literature for
                                    <Text style={{fontWeight: "bold"}}> diagnosing respiratory pathogens. {"\n"}{"\n"}</Text>

                                    <div/>
                                </Text>
                                <span>&nbsp;&nbsp;</span>
                                <div/>



                                <Text>
                                    <a href={"/"} style={{textDecoration: 'none'}}>

                                        <Text style={{fontWeight: "bold", color: "#FF1616"}}>
                                            LAMP
                                        </Text>
                                        <Text style={{fontWeight: "bold", color: "#000000"}}>
                                            Primer
                                        </Text>
                                        <Text style={{fontWeight: "bold", color: "#BCB8B1"}}>
                                            Bank
                                        </Text> </a>

                                    provides an intuitive, user-friendly interface, implying functions for browsing, searching,
                                    grouping, and downloading data that expedite the exploration of experimentally validated LAMP
                                    primers from the massively scattered literature, and the comparison of newly designed primers
                                    with the published, screened ones.
                                    <div/>
                                </Text>
                            </Text>
                            <span>&nbsp;&nbsp;</span>
                            <div/>



                            <Text>
                                <a href={"/"} style={{textDecoration: 'none'}}>

                                    <Text style={{fontWeight: "bold", color: "#FF1616"}}>
                                        LAMP
                                    </Text>
                                    <Text style={{fontWeight: "bold", color: "#000000"}}>
                                        Primer
                                    </Text>
                                    <Text style={{fontWeight: "bold", color: "#BCB8B1"}}>
                                        Bank
                                    </Text> </a> workflow comprises three steps: literature aggregation, data curation, and
                                database and website implementation. LAMP primers and their respective information were
                                retrieved from the reference literature in public primary databases, including PubMed, bioRxiv,
                                and medRxiv and manually curated into six specified descriptive categories. This resource
                                provides direct links to multiple databases, including PubMed, GenBank, and the NCBI Taxonomy
                                database, as well as to the original publication to fetch more details and facilitate
                                backtracking to the reference studies.
                                <div/>
                            </Text>
                            <span>&nbsp;&nbsp;</span>
                            <div/>




                            <Text>
                                <a href={"/"} style={{textDecoration: 'none'}}>

                                    <Text style={{fontWeight: "bold", color: "#FF1616"}}>
                                        LAMP
                                    </Text>
                                    <Text style={{fontWeight: "bold", color: "#000000"}}>
                                        Primer
                                    </Text>
                                    <Text style={{fontWeight: "bold", color: "#BCB8B1"}}>
                                        Bank
                                    </Text> </a>also affords <Text style={{fontWeight: "bold"}}> discrimination of
                                false-positive results </Text>by predicting the sizes of LAMP products according to mathematical
                                models and simulating agarose gel image for each primer set entry to facilitate discriminating
                                the incidence of false-positive diagnosis generated by non-specific amplification.{"\n"}{"\n"}
                            </Text>
                        <span>&nbsp;&nbsp;</span>
                        <span>&nbsp;&nbsp;</span>
                        <span>&nbsp;&nbsp;</span>
                        </Box>
                        <Box>
                            <Box style={{flexDirection: 'row', padding: 20}} className={"image2"}>
                                <img src={logo} alt=""/>
                            </Box>
                        </Box>
                    </Box>

                </Box>
                <span>&nbsp;&nbsp;</span>



                <Box>











                <Text className="Text" style={{fontSize: size}}>
                    {"\n"}
                    {"\n"}
                    {"\n"}



                    <a href={"/"} style={{textDecoration: 'none'}}>

                        <Text style={{fontWeight: "bold", color: "#FF1616"}}>
                            LAMP
                        </Text>
                        <Text style={{fontWeight: "bold", color: "#000000"}}>
                            Primer
                        </Text>
                        <Text style={{fontWeight: "bold", color: "#BCB8B1"}}>
                            Bank
                        </Text> </a> features:{"\n"}
                    <div/>
                    {"\n"}

                    <a href={"/Primers"} style={{textDecoration: 'none'}}> Primers</a> page for browsing all the LAMP
                    primers embedded in the database.
                    <div/>
                    <a href={"/Pathogens"} style={{textDecoration: 'none'}}>Pathogens</a> page for inspecting pathogens
                    in the database by providing a taxonomic perspective of the target pathogens.
                    <div/>
                    <a href={"/Publications"} style={{textDecoration: 'none'}}>Publications</a> page for presenting all
                    the reference articles that have been investigated to establish the database.
                    <div/>
                    <a href={"/access"} style={{textDecoration: 'none'}}>API</a> page for comprising a list of
                    application programming interface (API) endpoints enabling free public programmatic access to the
                    database for further analysis.
                    <div/>
                    <a href={"/Contact"} style={{textDecoration: 'none'}}>Contact</a> page for providing email addresses
                    to assist users in sending any suggestions, errors or submitting newly validated LAMP primers or
                    missing primers.
                </Text>


                <span>&nbsp;&nbsp;</span>

                <Text>

                    <a href={"/"} style={{textDecoration: 'none'}}>

                        <Text style={{fontWeight: "bold", color: "#FF1616"}}>
                            LAMP
                        </Text>
                        <Text style={{fontWeight: "bold", color: "#000000"}}>
                            Primer
                        </Text>
                        <Text style={{fontWeight: "bold", color: "#BCB8B1"}}>
                            Bank
                        </Text> </a> currently contains
                    {"\n"}
                    <div/>

                    <Text style={{fontWeight: "bold"}}>
                    <a href={"/Primers"} style={{textDecoration: 'none'}}> Primers:</a> 4,904  <Text style={{color: "#BCB8B1"}}>LAMP primers |
                </Text> 862 <Text style={{color: "#BCB8B1"}}> primer sets{"\n"}</Text> <div/>


                    <a href={"/Pathogens"} style={{textDecoration: 'none'}}>Pathogens:</a> 6 <Text style={{color: "#BCB8B1"}}>viral families |  </Text>14
                    <Text style={{color: "#BCB8B1"}}> bacterial families | </Text>3  <Text style={{color: "#BCB8B1"}}>fungal families |  </Text>46  <Text style={{color: "#BCB8B1"}}> species{"\n"}</Text>
                    <div/>
                    <a href={"/Publications"} style={{textDecoration: 'none'}}>Publications:</a> 466  <Text style={{color: "#BCB8B1"}}>articles </Text>
                </Text>
            </Text>
                <span>&nbsp;&nbsp;</span>
                <Text>
                    If you find <a href={"/"} style={{textDecoration: 'none'}}>

                    <Text style={{fontWeight: "bold", color: "#FF1616"}}>
                        LAMP
                    </Text>
                    <Text style={{fontWeight: "bold", color: "#000000"}}>
                        Primer
                    </Text>
                    <Text style={{fontWeight: "bold", color: "#BCB8B1"}}>
                        Bank
                    </Text> </a> useful, please cite:
                    <div/>
                    
                    Fatemeh Arabi-Jeshvaghani, Fatemeh Javadi-Zarnaghi, Hannah Franziska Löchel, Roman Martin, Dominik
                    Heider,  <Text style={{fontWeight: "bold"}}>LAMPPrimerBank, a manually curated database of experimentally validated loop-mediated 
                    isothermal amplification primers for detection of respiratory pathogens</Text>, Infection 2023, 
                    DOI: <a href={"https://doi.org/10.1007/s15010-023-02100-0"}>10.1007/s15010-023-02100-0</a>, 
                    PMID: <a href={"https://pubmed.ncbi.nlm.nih.gov/37828369/"}>37828369</a> 

                </Text>
                <span>&nbsp;&nbsp;</span>
                <span>&nbsp;&nbsp;</span>
                <div/>
                <Text>  {"\n"}
                    {"\n"} <div/>
                    The information provided is for research only and may not be used for clinical decision making or
                    intervention. Please contact <Text
                        style={{fontSize: size, color: brand}}> fa.javadi@sci.ui.ac.ir </Text> for further information.

                    {"\n"}
                    {"\n"}

                </Text>
                <span>&nbsp;&nbsp;</span>
                <span>&nbsp;&nbsp;</span>
            </Box>
            </Box>


        );
    }
}
