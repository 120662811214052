import React, {Component} from "react";
import {render} from "react-dom";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {theme} from "./theme";
import "./Design.css"
import {Primers, NotFound, About, Access, Disclosure, Pathogens, Publications, Contact} from "./pages";
import {Box, Grid, Grommet, Main, Text} from 'grommet';
import Container from '@material-ui/core/Container';
import Divider from "@material-ui/core/Divider";
import Navigation from "./components/Navigation";
import {LoadingBar} from "./LoadingBar";
import {Details} from "./pages/details/description";
import {Header} from "./Header";


const items = [

    {
        name: "  Home",
        pathto: "/",
        value: 1,
    },
    {
        name: "  Primers",
        pathto: "/primers",
        value: 2,
    },
    {
        name: "  Pathogens",
        pathto: "/pathogens",
        value: 3,
    },


    {
        name: "  Publications",
        pathto: "/publications",
        value: 4,
    },
    {
        name: "  Contact",
        pathto: "/contact",
        value: 5,
    },
    {
        name: "  API",
        pathto: "/access",
        value: 6,
    },
    {
        name: "  Disclosure",
        pathto: "/disclosure",
        value: 7,
    }

];

const url = require("./data/url");

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            publicationData: [],
            pathogenData: [],
            drugData: [],
            interactionData: [],
            loading: true
        };
    }


    componentDidMount() {

        Promise.all([
            fetch(url.publications),
            fetch(url.pathogen),
            fetch(url.lamp)

        ])
            .then(([res1, res2, res3]) => Promise.all([res1.json(), res2.json(), res3.json()]))
            .then(([publicationData, pathogenData, lampData]) => this.setState({
                publicationData: publicationData.data,
                pathogenData: pathogenData.data,
                lampData: lampData.data,
                loading: false
            })).catch(error => console.log(`Error in promises ${error}`))
    }


    render() {

        function DetailedPage({match}) {


            return <Details
                url={url.details + match.params.id}
            />
                ;
        }

        const {publicationData, pathogenData, lampData, loading} = this.state;


        return (

            <Grommet theme={theme} full>
                <Grid fill rows={["auto", "flex", "auto"]}>
                    <Header/>
                    <Divider/>
                    <Box>

                        <Grommet theme={theme} full>
                            <Grid fill columns={["auto", "flex", "auto"]}>
                                <BrowserRouter>

                                    <Box direction="row" full justify={"start"} align="start"
                                         background={"white"}>

                                        <Navigation items={items}/>

                                    </Box>
                                    {/* <Divider/>
                                            <Box pad="medium">*/}
                                    <Main>
                                        {loading ? <LoadingBar/> :
                                            <Container maxWidth={"xl"}>

                                                <Switch>

                                                    <Route path="/" exact component={About}/>

                                                    <Route path="/access" component={Access}/>
                                                    <Route path="/contact" component={Contact}/>

                                                    <Route path="/primers">
                                                        <Primers
                                                            data={lampData}/>

                                                    </Route>

                                                    <Route path="/pathogens">
                                                        <Pathogens
                                                            data={pathogenData}/>
                                                    </Route>


                                                    <Route path="/publications">
                                                        <Publications
                                                            data={publicationData}/>
                                                    </Route>

                                                    <Route path="/disclosure">
                                                        <Disclosure/>
                                                    </Route>


                                                    <Route path="/primer/:id" component={DetailedPage}/>


                                                    <Route component={NotFound}/>

                                                </Switch>

                                            </Container>}
                                    </Main>
                                </BrowserRouter>

                            </Grid>

                        </Grommet>


                    </Box>
                    {/*  </Grommet>


                    </Box>*/}
                    <Box tag="footer" pad="xxsmall" background={"#BCB8B1"}>
                        <Box style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>


                            <Text style={{color: "#000000"}}>{"\t"} <a href={"/disclosure"}> Disclosure</a>
                            </Text>

                            <Text style={{color: "#000000"}}>{"\t"} <a href="https://www.uni-marburg.de/en/privacy"
                                                                       rel="noopener noreferrer"
                                                                       target="_blank"> Privacy Statement</a>
                            </Text>


                            <Text color="#000000" style={{paddingRight: 10}}> v1.0.0 </Text>
                        </Box>
                    </Box>

                </Grid>
            </Grommet>
        );
    }
}

render(<App/>, document.getElementById("root"));
