import React, {Component} from "react";
import {Box, Grid, Text} from "grommet";
import {LoadingBar} from "../../../LoadingBar";
import "./Publications.css";
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import {PublicationDetails} from "./PublicationDetails";
import Divider from "@material-ui/core/Divider";
import {DetailTable} from "../../content";
import ElementDFP from "./ElementDFP";



function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


export class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            primerData: [],

            loading: true
        };
    }

    componentDidMount() {
        const {url} = this.props;
        fetch(url)
            .then(response => response.json())
            .then(primerData => this.setState({primerData: primerData, loading: false}));

    }
    ;
    publication;


    render() {

        const {primerData, loading} = this.state;



        return (
            <Box align={"start"}>
                <span>&nbsp;&nbsp;</span>

                <Box>
                    {loading ? <LoadingBar/> :

                        <Grid fill rows={["auto", "flex", "auto"]}>


                            <Box fill align="start" justify="center" pad="small">

                                <Text style={{fontSize: 48, color: "#FF1616"}}>
                                    {primerData.id}
                                </Text>
                            </Box>
                            <span>&nbsp;&nbsp;</span>
                            <Divider/>
                            <span>&nbsp;&nbsp;</span>

                            <h2> Pathogen Description </h2>


                            <DetailTable

                                columns={[
                                    {title: 'Target Pathogen', field: 'name'},
                                    {title: 'Pathogen Name(s)', field: 'synonym'},
                                    {title: 'NCBI Taxonomy ID', field: 'ncbi_taxonomy'},
                                    {title: 'Order', field: 'order'},
                                    {title: 'Family', field: 'family'},
                                    {title: 'Genus', field: 'genus'},
                                    {title: 'Species', field: 'species'},
                                ]}
                                data={[
                                    {
                                        name: primerData.pathogen.name,
                                        synonym: primerData.pathogen.synonym,
                                        ncbi_taxonomy:
                                            <a href={"https://www.ncbi.nlm.nih.gov/Taxonomy/Browser/wwwtax.cgi?id=" + primerData.pathogen.ncbi_taxonomy}
                                               rel="noopener noreferrer"
                                               target="_blank"> {primerData.pathogen.ncbi_taxonomy} </a>,

                                        order: primerData.pathogen.order,
                                        family: primerData.pathogen.family,
                                        genus: primerData.pathogen.genus,
                                        species: primerData.pathogen.species

                                    },

                                ]}

                            />

                            <span>&nbsp;&nbsp;</span>
                            <Divider/>
                            <span>&nbsp;&nbsp;</span>


                            <Box>

                                <Box>
                                    <h2>Primer Description</h2>


                                    <DetailTable


                                        data={primerData.primer}


                                        columns={
                                            [
                                                {title: 'Primer Name', field: 'name', defaultSort: "asc"},
                                                {title: 'Sequence (5´-3´)', field: "sequence"},
                                                {title: 'Length (bp)', field: "length"},
                                                {title: 'Primer Final Concentration (µM)', field: "concentration"},
                                                {title: 'GC Content (%)', field: "gc_content"},
                                                {
                                                    title: 'Predicted Melting Temperature (°C)',
                                                    field: "melting_temperature"
                                                },
                                                {title: 'Molecular Weight', field: "molecular_weight"},

                                                {title: 'Reference GenBank accession number', field: 'genbank'},
                                                {title: 'Positions in reference GenBank accession number', field: "position"},

                                            ]
                                        }
                                    />

                                </Box>
                                <span>&nbsp;&nbsp;</span>
                                <Text>Data only shown, if available</Text>

                            </Box>


                            <span>&nbsp;&nbsp;</span>
                            <Divider/>
                            <span>&nbsp;&nbsp;</span>

                            <h2>Gene Description</h2>


                            <DetailTable

                                columns={[
                                    {title: 'Target Gene', field: 'gene_region'},
                                ]}
                                data={[
                                    {
                                        gene_region: primerData.primer[0].gene_region,

                                    },

                                ]}

                            />

                            <span>&nbsp;&nbsp;</span>
                            <Text>Data only shown, if available</Text>


                            <span>&nbsp;&nbsp;</span>
                            <Divider/>
                            <span>&nbsp;&nbsp;</span>


                            <h2>Assay Description</h2>
                            <DetailTable


                                data={primerData.assay}


                                columns={
                                    [
                                        {title: 'Application', field: 'application', defaultSort: "asc"},
                                        {title: 'Assay', field: 'assay'},
                                        {title: 'LAMP Primer Designing Software', field: 'software'},
                                        {title: 'Reaction Time (min)', field: "reaction_time"},

                                        {title: 'Assay Temperature (°C)', field: "temperature"},


                                        {title: 'Readout System(s)', field: "system"},

                                        {title: 'Limit of Detection (LoD)', field: "lod"},
                                        {title: 'Sensitivity (%)', field: "sensitivity"},
                                        {title: 'Specificity (%)', field: "specificity"},
                                    ]
                                }
                            />
                            <span>&nbsp;&nbsp;</span>
                            <Text>Data only shown, if available</Text>

                            <span>&nbsp;&nbsp;</span>
                            <Divider/>
                            <span>&nbsp;&nbsp;</span>

                            <h2>Publication Description</h2>

                            <DetailTable
                                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                                columns={[
                                    {title: 'Year of Publication', field: 'year'},
                                    {title: 'Title', field: 'title'},
                                    {title: 'Journal', field: 'journal'},
                                    {title: 'PMID', field: 'pmid'},
                                    {title: 'DOI', field: 'doi'},
                                    {title: 'Author(s)', field: 'author'},
                                ]}
                                data={[
                                    {
                                        year: primerData.publication.year,
                                        title: primerData.publication.title,
                                        journal: primerData.publication.journal,
                                        pmid: <a href={'https://pubmed.ncbi.nlm.nih.gov/' + primerData.publication.pmid}
                                                 rel="noopener noreferrer"
                                                 target="_blank">{primerData.publication.pmid}</a>,
                                        doi: <a href={'https://doi.org/' + primerData.publication.doi}
                                                rel="noopener noreferrer"
                                                target="_blank">{primerData.publication.doi}</a>,
                                        author: primerData.publication.authors,
                                    },

                                ]}

                                detailPanel={[

                                    {

                                        tooltip: 'Details',
                                        render: () => {
                                            return (
                                                <PublicationDetails
                                                    data={primerData.publication}


                                                />


                                            )
                                        }
                                    },


                                ]}

                            />


                            <span>&nbsp;&nbsp;</span>
                            <Divider/>
                            <span>&nbsp;&nbsp;</span>


                            <h2>Predicted banding patterns in an agarose gel</h2>

                            <ElementDFP
                                name={"DFP"}
                                id={primerData.dfp}
                                lamp={primerData.id}
                            />


                            <h2>Notes</h2>

                            <Text>{primerData.notes}</Text>
                            <span>&nbsp;&nbsp;</span>
                            <Divider/>
                            <span>&nbsp;&nbsp;</span>



                        </Grid>
                    }
                </Box>


            </Box>


        );
    }
}
