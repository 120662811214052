import {Box, Grid} from "grommet";

import React from "react";
import "./Header.css"
import logo from "./images/LAMPPrimerBank Logo.png";


export const Header = () => (
    <Grid fill rows={["auto", "flex", "auto"]}>
        <Box>
            <Box style={{flexDirection: 'row', padding: 20}}>
                <a href={"/"} style={{textDecoration: 'none'}}>
                    <Box style={{flexDirection: 'row', padding: 20}} className={"image3"}>
                        <img src={logo} alt=""/>
                    </Box>
                 {/*   <Text className="header" style={{fontSize: 48, fontWeight: "bold", color: "#FF1616"}}>
                        LAMP
                    </Text>
                    <Text className="header" style={{fontSize: 48, fontWeight: "bold", color: "#000000"}}>
                        Primer
                    </Text>
                    <Text className="header" style={{fontSize: 48, fontWeight: "bold", color: "#BCB8B1"}}>
                        Bank
                    </Text> */}</a>
            </Box>
        </Box>
    </Grid>

);
