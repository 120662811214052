import React from "react";



/**
 * @return {null}
 */
export default function Element(props) {

    let id;
    id = props.id;


    if (id != null && id !== "") {
        return (
            <div>

                <h2 style={{color: '#FF1616'}}> {id}  </h2>
            </div>
        );
    }
    return null;
}
