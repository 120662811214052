import React, {Component} from "react";
import {GroupableTable} from "./content"
import {Box, Heading} from "grommet";


export class Primers extends Component {
    genbank;

    render() {
        let url = require("../data/url");
        const {data} = this.props;

        function Details({match}) {


            return <Details
                url={url.details + match.params.id}
            />
                ;
        }

        return (


            <Box fill align="start" justify="center">
                <Heading>Primers</Heading>

                <GroupableTable

                    data={data}


                    columns={
                        [
                            {
                                title: 'LAMPPrimerBank ID',
                                field: 'lamp_id',
                                render: rowData => <a href={'/primer/' + rowData.lamp_id} rel="noopener noreferrer"
                                                      target="_blank">{rowData.lamp_id}</a>,
                                defaultSort: "asc",
                                grouping: false
                            },
                            {title: 'Primer Name', field: 'name'},
                            {title: 'Sequence (5´-3´)', field: 'sequence'},
                            {title: 'Target Pathogen', field: "pathogen_name"},
                            {
                                title: 'GenBank',
                                field: "genbank",
                                render: rowData => <a href={'https://www.ncbi.nlm.nih.gov/nuccore/' + rowData.genbank}
                                                      rel="noopener noreferrer" target="_blank">{rowData.genbank}</a>,
                                grouping: false
                            },
                            {title: 'Target Gene', field: "gene_region"},
                            {title: 'Year of Publication', field: "publication_year"},
                            {title: 'Pathogen type', field: "pathogen_type"},

                        ]
                    }


                />


            </Box>
        );
    }
}
