import React from "react";
import {Box, Paragraph} from "grommet";


export const NotFound = () => (
    <Box fill align="center" pad={{top: "large", horizontal: "small"}}>
        <Paragraph textAlign="center" color="dark-4">
            The page you requested does not exist or has been removed.
        </Paragraph>
    </Box>
);
