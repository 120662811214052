import {grommet} from "grommet";
import {deepMerge} from "grommet/utils";

export const theme = deepMerge(grommet, {

    "global": {
        "font": {
            size: "min(max(10px, 1vw), 15px)"
        },
        "size": {
            sidebar: "1vw"
        },
        "padding": {
            size: "0",
            width: "0"
        },

        "colors": {
            "brand": "#FF1616",
            "border": {
                "dark": "#ebebeb",
                "light": "#444444"
            },
            "background": "#FFFFFF",
            "placeholder": "#ebebeb",
        },


    },


});
