import React, {Component} from "react";
import {Box} from "grommet";
import {DetailTable} from "../../content";
import LinkedElement from "./LinkedElement";


export class PathogenPrimerAccordion extends Component {


    render() {

        const {data, name, ncbi} = this.props;


        console.log(data);

        return (

            <Box fill align="start" justify="center" background={"#fafafa"} pad="small">

                <Box>
                    <h2>{name}</h2>
                </Box>

                <LinkedElement
                    name={"NCBI Taxonomy ID"}
                    id={ncbi}
                    link={"https://www.ncbi.nlm.nih.gov/Taxonomy/Browser/wwwtax.cgi?id="}
                />


                <DetailTable


                    data={data}


                    columns={
                        [
                            {
                                title: 'LAMPPrimerBank ID',
                                field: 'id',
                                render: rowData => <a href={'/primer/' + rowData.id} rel="noopener noreferrer"
                                                      target="_blank">{rowData.id}</a>,
                                defaultSort: "asc"
                            },
                            {title: 'Target Gene', field: 'target'},
                            {title: 'Year of Publication', field: 'publication_year'},
                        ]


                    }

                />


            </Box>

        );
    }
}
