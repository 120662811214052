import React from "react";
import {Box, Paragraph, Text} from "grommet";
import {DetailTable} from "../../content";



/**
 * @return {null}
 */
export default function ElementDFP(props) {


    console.log(props);


    if (typeof props !== "undefined" && props.hasOwnProperty("id") && props.id.hasOwnProperty("band_1") && props.id.band_1 !=="") {
        let id;
        id = props.id;
        const lamp=props.lamp;



        return (
            <Box>

            <Box  align={"baseline"}
                direction="row"
            >


                <Box

                    pad={"large"}
                    >
                    <Paragraph>
                        Predicted LAMP products' lengths </Paragraph>
                    <DetailTable

                        columns={[
                            { title: 'Band', field: 'band' },
                            { title: 'Size (bp)', field: 'size' },
                        ]}
                        data={[
                            { band: 'Band 7', size: id.band_7},
                            { band: 'Band 6', size: id.band_6},
                            { band: 'Band 5', size: id.band_5},
                            { band: 'Band 4', size: id.band_4},
                            { band: 'Band 3', size: id.band_3},
                            { band: 'Band 2', size: id.band_2},
                            { band: 'Band 1', size: id.band_1},
                        ]}

                    />






                </Box>



                <Box

                >



                    <Box align={"left"} style={{padding: 20}}>
                        <Paragraph>
                            Simulated agarose gel image</Paragraph>


                        <Text>*Lane 1: GeneRuler 1 kb Plus DNA Ladder</Text>
                        <Text>*Lane 2: Predicted LAMP products</Text>
                        <img width="150"

                            src={"https://lampprimer.mathematik.uni-marburg.de/gels/" +lamp + ".png"}
                            alt={"https://lampprimer.mathematik.uni-marburg.de/gels/" + lamp + ".png"}
                        >

                        </img>


                    </Box>
                </Box>

            </Box>
    </Box>




        );
    }
    return null;
}
