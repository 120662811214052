import React from "react";

import {Box, Heading, Text} from "grommet";


const size = 20;
const brand = "#76B7B2";


export const Access = () => (
    <Box fill align="start">
        <Heading>API</Heading>

        <Text style={{fontSize: size}}>
            There is an open API for access programmatically to the database. The API will print a JSON
            output:{"\n"}{"\n"}

            <Box className="List" style={{fontSize: size, paddingTop: 20, paddingLeft: 80, paddingBottom: 20}}>
                <Text style={{fontSize: size, color: brand}}> <a
                    href="https://lampprimer.mathematik.uni-marburg.de/api.php?table=lamp&action=list"
                    rel="noopener noreferrer" target="_blank"> LAMP (full-data)</a>
                </Text>

                <Text className={"ListItem"} style={{paddingBottom: 20}}>
                    https://lampprimer.mathematik.uni-marburg.de/api.php?table=lamp&action=list{"\n"}{"\n"}
                </Text>

                <Text style={{fontSize: size, color: brand}}> <a
                    href="https://lampprimer.mathematik.uni-marburg.de/api.php?table=lamp&action=view&id=LPB00001"
                    rel="noopener noreferrer" target="_blank"> LAMP (single entry)</a>
                </Text>

                <Text className={"ListItem"} style={{paddingBottom: 20}}>
                    https://lampprimer.mathematik.uni-marburg.de/api.php?table=lamp&action=view&id=LPB00001{"\n"}{"\n"}
                </Text>

                <Text style={{fontSize: size, color: brand}}> <a
                    href="https://lampprimer.mathematik.uni-marburg.de/api.php?table=primer&action=list"
                    rel="noopener noreferrer" target="_blank"> Primers</a>
                </Text>

                <Text className={"ListItem"} style={{paddingBottom: 20}}>
                    https://lampprimer.mathematik.uni-marburg.de/api.php?table=primer&action=list{"\n"}{"\n"}
                </Text>

                <Text style={{fontSize: size, color: brand}}> <a
                    href="https://lampprimer.mathematik.uni-marburg.de/api.php?action=list&table=pathogen"
                    rel="noopener noreferrer" target="_blank">Pathogens</a>
                </Text>

                <Text className={"ListItem"} style={{paddingBottom: 20}}>
                    https://lampprimer.mathematik.uni-marburg.de/api.php?action=list&table=pathogen{"\n"}{"\n"}
                </Text>

                <Text style={{fontSize: size, color: brand}}> <a
                    href="https://lampprimer.mathematik.uni-marburg.de/api.php?action=list&table=publication"
                    rel="noopener noreferrer" target="_blank"> Publications</a>
                </Text>
                <Text className={"ListItem"} style={{paddingBottom: 20}}>
                    {"\t"} {"\t"}https://lampprimer.mathematik.uni-marburg.de/api.php?action=list&table=publication{"\n"}{"\n"}
                </Text>


            </Box>
        </Text>


    </Box>

);
