import React, {Component} from "react";
import {Box, Heading} from "grommet";
import {GroupableTable} from "./content";
import {PathogenPrimerAccordion} from "./details/description/PathogenPrimerAccordion";


export class Pathogens extends Component {
    lamp_id;

    constructor(props) {
        super(props);
        this.state = {
            data: [],

        };
    }


    render() {
        const {data} = this.props;


        return (

            <Box fill align="start" justify="center">
                <Heading>Pathogens</Heading>


                <GroupableTable
                    onRowClick={(event, rowData, togglePanel) => togglePanel()}
                    data={data}

                    columns={
                        [
                            {title: 'Target Pathogen', field: 'name', defaultSort: "asc"},
                            {title: 'Pathogen Name(s)', field: "synonym"},
                            {
                                title: 'NCBI Taxonomy',
                                field: "ncbi_taxonomy",
                                render: rowData => <a
                                    href={'https://www.ncbi.nlm.nih.gov/Taxonomy/Browser/wwwtax.cgi?id=' + rowData.ncbi_taxonomy}
                                    rel="noopener noreferrer" target="_blank">{rowData.ncbi_taxonomy}</a>,
                                grouping: false
                            },
                            {title: 'Order', field: "order"},
                            {title: 'Family', field: "family"},
                            {title: 'Genus', field: "genus"},
                            {title: 'Species', field: "species"},
                            {title: 'Pathogen type', field: "type"},
                        ]
                    }

                    detailPanel={[

                        {

                            tooltip: 'Details',
                            render: rowData => {
                                return (
                                    <PathogenPrimerAccordion
                                        data={rowData.lamp_id}
                                        name={rowData.name}
                                        ncbi={rowData.ncbi_taxonomy}


                                    />
                                )
                            }
                        },


                    ]}


                />
                <span>&nbsp;&nbsp;</span>

                <span>&nbsp;&nbsp;</span>
            </Box>


        );
    }
}
