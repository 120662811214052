import React, {Component} from "react";
import {Box, Heading} from 'grommet';
import {GroupableTable} from "./content";
import {PrimerAccordion, PublicationDetails} from "./details/description";


export class Publications extends Component {


    render() {

        const {data} = this.props;

        return (


            <Box fill align="start" justify="center">
                <Heading>Publications</Heading>

                <GroupableTable
                    onRowClick={(event, rowData, togglePanel) => togglePanel()}
                    data={data}


                    columns={
                        [
                            {title: 'Year of Publication', field: 'year', defaultSort: "asc"},
                            {title: 'Title', field: "title"},
                            {title: 'Author(s)', field: "authors"},
                            {title: 'Journal', field: "journal"},
                            {
                                title: 'PMID',
                                field: "pmid",
                                render: rowData => <a href={'https://pubmed.ncbi.nlm.nih.gov/' + rowData.pmid}
                                                      rel="noopener noreferrer" target="_blank">{rowData.pmid}</a>,
                                grouping: false
                            },
                            {
                                title: 'DOI',
                                field: "pmid",
                                render: rowData => <a href={'https://doi.org/' + rowData.doi} rel="noopener noreferrer"
                                                      target="_blank">{rowData.doi}</a>,
                                grouping: false
                            },
                            /*   {title: 'Target virus', field: "virus"},*/
                        ]
                    }
                    detailPanel={[

                        {

                            tooltip: 'Publications',
                            render: rowData => {
                                return (
                                    <div>
                                        <PublicationDetails
                                            data={rowData}

                                        />
                                        <PrimerAccordion
                                            data={rowData.lamp_id}


                                        />

                                    </div>


                                )
                            }
                        },

                    ]}


                />


            </Box>
        );
    }
}
