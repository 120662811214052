import React from 'react';
import {Box} from "grommet";
import RoutedTab from "./RoutedTab";
import "../Header.css"
import {Tabs} from "@material-ui/core";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";



const theme = createTheme({
    palette: {
        primary: {main: "#FF1616",},
        secondary: {main: "#000000",},
    },
    typography: {useNextVariants: true},
});


export default function Sidebar(props) {

    const [value, setValue] = React.useState(1);
    const [expanded] = React.useState(true);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const routedTab = props.items.map(({icon, name, pathto, value}) => (
        <RoutedTab
            key={value}
            value={value}
            symbol={icon}
            path={pathto}
            label={name}
        />));




    if (expanded) {
        return <Box
            fill="vertical"
            //width="250px"
            //background="white"
            elevation="medium" overflow="auto" >

            <ThemeProvider theme={theme}>
                <div>
                    <Tabs
                        variant="fullWidth"
                        centered
                        orientation="vertical"
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"                    >
                        {routedTab}
                    </Tabs>
                </div>
            </ThemeProvider>



        </Box>;
    }
    return <Box elevation="medium" fill="horizontal" overflow="auto" >



    </Box>;
}



