import React from "react";
import {Text} from "grommet";


/**
 * @return {null}
 */
export default function LinkedElement(props) {
    const name = props.name;
    const id = props.id;
    const link = props.link + props.id;

    if (id != null && id !== "") {
        return <Text>{name}: <a href={link} rel="noopener noreferrer" target="_blank">{id} </a></Text>;
    }
    return null;
}
