import React, {Component} from "react";
import {Box} from "grommet";

import {DetailTable} from "../../content";


export class PrimerAccordion extends Component {


    render() {

        const {data} = this.props;


        console.log(data);

        return (

            <Box fill align="start" justify="center" background={"#fafafa"} pad="small">


                <DetailTable


                    data={data}


                    columns={
                        [
                            {
                                title: 'LAMPPrimerBank ID',
                                field: 'id',
                                render: rowData => <a href={'/primer/' + rowData.id} rel="noopener noreferrer"
                                                      target="_blank">{rowData.id}</a>,
                                defaultSort: "asc"
                            },
                            {title: 'Target Pathogen', field: 'pathogen'},
                            {title: 'Target Gene', field: 'target'},
                        ]


                    }


                />


            </Box>

        );
    }
}
