import React, {Component, forwardRef} from "react";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import MaterialTable from "material-table";


const tableIcons = {
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
};


export class DetailTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRow: null
        }
    }

    render() {
        const {columns, data, detailPanel, onRowClick} = this.props;
        //const newData=data.matchID.includes(id);

        return <MaterialTable


            data={data}
            columns={columns}
            icons={tableIcons}
            detailPanel={detailPanel}
            onRowClick={onRowClick}
            options={{
                toolbar: false,
                search: false,
                paging: false,
                rowStyle: rowData => ({
                    backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
                })
            }}


        >

        </MaterialTable>;
    }

}

