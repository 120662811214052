import React from "react";
import {Text} from "grommet";


/**
 * @return {null}
 */
export default function Element(props) {
    const name = props.name;
    let id;
    id = props.id;


    if (id != null && id !=="") {
        return (
            <div>
            <h3>{name}:</h3>
            <Text> {id}  </Text>
            </div>
        );
    }
    return null;
}
