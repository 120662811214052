import React, {Component} from "react";
import {Box} from "grommet";
import LinkedElement from "./LinkedElement";
import Element from "./Element";

import ElementTitle from "./ElementTitle";


export class PublicationDetails extends Component {
    authors;

    constructor(props) {
        super(props);
        this.state = {
            drugData: {},
        };
    }

    render() {
        const {data} = this.props;

        return (

            <Box fill align="start" justify="center" background={"#fafafa"} pad="small">


                <ElementTitle
                    name={"Title"}
                    id={data.title}
                />


                <Element
                    name={"Author(s)"}
                    id={data.authors}
                />

                <Element
                    name={"Journal"}
                    id={data.journal}
                />

                <Element
                    name={"Year"}
                    id={data.year}
                />

                <Element
                    name={"Abstract"}
                    id={data.abstract}
                />

                <LinkedElement
                    name={"DOI"}
                    id={data.doi}
                    link={"https://doi.org/"}
                />

                <LinkedElement
                    name={"PMID"}
                    id={data.pmid}
                    link={"https://pubmed.ncbi.nlm.nih.gov/"}
                />


            </Box>

        );
    }
}
